$max-width: 920px;

.menu {
  display: flex;
  align-items: center;
  text-align: center;
}

.main-menu {
  @media only screen and (max-width: $max-width) {
    display: none;
    transition: all 0.5s ease;

    &.display {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: calc(100vh - 80px);
      position: absolute;
      top: 80px;
      background-color: rgba(0, 0, 0, 0.9);

      .menuItem {
        width: 100%;
        margin-top: 1rem;
        margin-bottom: 1rem;

        &:hover {
          border: none;
        }

        & + .menuItem {
          margin-left: 0;
        }
      }
    }
  }
}

.menuItem {
  list-style: none;
  align-self: center;
  justify-self: center;
}

.menuItem + .menuItem {
  margin-left: 2rem;

  @media only screen and (max-width: $max-width) {
    margin-left: 1rem;
  }
}
