.wrapper {
  min-height: 100vh;
}

.main {
  min-height: calc(100vh - 80px);
  display: flex;
  justify-content: center;
  align-items: center;
}
